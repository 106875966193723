import React from "react"
import { AslPromo } from "../components/aslPromo";
import LoadingAnimation from "../components/loadingAnimation"

import useTranslations from "../hooks/useTranslations"

const OnlineBookingPage = () => {
  const { loadingAnimation, bookingPage } = useTranslations()
  return (
    <>
      <div className="px-6 py-16 mx-auto max-w-7xl md:pt-28">
        <h1 className="mb-4 text-xl md:text-4xl md:mb-6">{bookingPage.headline}</h1>
        <p className="max-w-md text-sm text-gray-500">{bookingPage.text}</p>
      </div>
      <div className="grid justify-items-center min-h-[500px]">
        <div className="col-start-1 row-start-1 text-xs opacity-50 rw-loading-animation">
          <LoadingAnimation className="w-24 h-24 mx-auto"/>
          <div className="mt-1">{loadingAnimation.text1}</div>
        </div>
        <div className="relative col-start-1 row-start-1 duration-700 opacity-0 rw-widget-delayed">
          <rentware-search show-location="off" view="slots" show-filters="on" />
        </div>
      </div>
    </>
  );
};

export default OnlineBookingPage;